/* Popup Modal CSS */

.popup-logo{
  position: absolute;
  top: -20px; 
  left: -9px; 
}
.popup-desc{
  font-size: 20px;

  font-weight: bold;
  color: #0b1e68;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }
  
  .popup-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    position: relative;
  }
  
  .popup-logo {
    width: 50px;
    margin-bottom: 10px;
  }
  
  .popup-text {
    margin-bottom: 20px;
  }
  
  .popup-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .popup-description {
    margin-bottom: 20px;
    font-size: 1.1em;
  }
  
  .popup-input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .popup-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px 0 0 20px;
    outline: none;
  }
  
  .popup-input button {
    padding: 10px 20px;
    border: none;
    border-radius: 0 20px 20px 0;
    background-color: rgb(220, 87, 83);
    color: #ffffff;
    cursor: pointer;
  }
  
  .popup-input button:hover {
    background-color: rgb(200, 67, 63);
  }
  
  .popup-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .popup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #34495e;
    color: #ffffff;
  }
  
  .popup-button:hover {
    background-color: #2c3e50;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .error-text {
    color: red;
  }
  