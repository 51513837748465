

.tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -80px;
  left: 100%; 
  margin-left: 10px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.copy-icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}









.quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

  }

  .quote-content {
    position: relative;
    padding-right: 30px; 
  }
  
  .copy-icon {
    position: absolute;
    top: -30px; 
    right: -30px; 
    cursor: pointer;
    color: #ffffff; 
  }
  
  
  .quote-box {
  
    background-color:rgb(19, 58, 133) ;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgb(11, 30, 104);
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
  
  .quote-content {
    margin-bottom: 20px;
  }
  
  .quote {
    font-size: 1.5em;
    font-style: italic;
    color: #ffffff; 
  }
  
  .author {
    font-size: 1.2em;
    color: #ffffff; 
  }
  
  .next-quote-btn {
    background-color:rgb(252, 169, 3); 
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .next-quote-btn:hover {
    background-color: rgb(3, 5, 10);
    color: #ffffff;
  }
  
 
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    text-align: center;
  }
  
  .popup-logo {
    width: 50px;
  }
  
  .popup-text {
    margin-bottom: 20px;
  }
  
  .popup-title {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .popup-description {
    margin-bottom: 10px;
  }
  
  .popup-input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .popup-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .popup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(11, 30, 104);
    color: #ffffff;
  }
  
  .popup-button:hover {
    background-color: rgb(11, 30, 104);
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .error-text {
    color: red;
  }
  